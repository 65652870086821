import QRCode from 'qrcode.react'
import { CardInterface } from './CardOptions'
import { useState, Dispatch, SetStateAction, ReactElement } from 'react'
import { Box, Grid, Dialog, Button, DialogContent, Container, Typography } from '@mui/material'

function SimpleDialog ({ open, onClose, qrValue }: { open: boolean, onClose: Dispatch<SetStateAction<boolean>>, qrValue: string }): ReactElement {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <QRCode value={qrValue} size={200} />
      </DialogContent>
    </Dialog>
  )
}

export default function PageCard ({ customer }: { customer: CardInterface }): ReactElement {
  const [open, setOpen] = useState(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  const imgVal = `https://s3.marroquin.dev/tarjeta/${customer.appLogo}`
  const qrValue = `https://tarjeta.marroquin.dev/${customer.appPath}`

  return (
    <Box sx={{ textAlign: 'center', alignItems: 'center', py: '15px' }}>
      <SimpleDialog open={open} onClose={handleClose} qrValue={qrValue} />
      <Container maxWidth='md'>
        <Box sx={{ height: '30vh', backgroundImage: `url(${imgVal})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} />
        <Box sx={{ py: '20px' }}>
          <Button variant='outlined' onClick={handleOpen}>Ver QR</Button>
        </Box>
        <Grid container spacing={2}>
          {
            customer.iconArray.map((item, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <a href={item.links} target='_blank' rel='noreferrer'>
                    <div>
                      {item.image}
                    </div>
                    <Typography
                      variant='body1' sx={{
                        fontSize: {
                          xs: '10px',
                          sm: '15px',
                          md: '20px'
                        },
                        color: '#79491D',
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textTransform: 'uppercase'
                      }}
                    >
                      {item.title}
                    </Typography>
                  </a>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </Box>
  )
}
