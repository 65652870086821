import { ReactNode } from 'react'
import { Twitter, YouTube, Language, LinkedIn, WhatsApp, Instagram, LocationOn, MailOutline, FacebookRounded } from '@mui/icons-material'

interface ISocial { image: ReactNode, title: string, links: string }
export interface CardInterface { appPath: string, appLogo: string, iconArray: ISocial[] }

const AddLanguage = (title: string): ISocial => {
  return {
    image: <Language sx={{ color: '#00A9E4', width: '100%', height: '100%' }} />,
    title: title,
    links: `https://www.${title}`
  }
}

const AddWhatsApp = (phone: number): ISocial => {
  const txtWhatsApp = 'Buenos días le escribo a través de su tarjeta de contacto'
  const newWhatsApp = txtWhatsApp.replaceAll(' ', '%20')
  const txtPhone = phone.toString()
  const newPhone = txtPhone.length === 12 ? `${txtPhone.substring(0, 2)} ${txtPhone.substring(2, 4)} ${txtPhone.substring(4, 8)} ${txtPhone.substring(8, 12)}` : '00 00 0000 0000'
  return {
    image: <WhatsApp sx={{ color: '#00A73E', width: '100%', height: '100%' }} />,
    title: `+${newPhone}`,
    links: `https://api.whatsapp.com/send?phone=+${phone}&text=${newWhatsApp}`
  }
}

const AddFacebook = (title: string): ISocial => {
  return {
    image: <FacebookRounded sx={{ color: '#0066A7', width: '100%', height: '100%' }} />,
    title: `@${title}`,
    links: `https://www.facebook.com/${title}`
  }
}

const AddSendMail = (email: string): ISocial => {
  return {
    image: <MailOutline sx={{ color: '#0066A7', width: '100%', height: '100%' }} />,
    title: 'Enviar correo',
    links: `mailto:${email}`
  }
}

const AddInstaNew = (title: string): ISocial => {
  return {
    image: <Instagram sx={{ color: '#FF1885', width: '100%', height: '100%' }} />,
    title: `@${title}`,
    links: `https://www.instagram.com/${title}`
  }
}

const AddTwitters = (profileURL: string): ISocial => {
  return {
    image: <Twitter sx={{ color: '#00A9E4', width: '100%', height: '100%' }} />,
    title: `@${profileURL}`,
    links: `https://www.twitter.com/${profileURL}`
  }
}

const AddLocation = (mapsLocation: string): ISocial => {
  return {
    image: <LocationOn sx={{ color: '#FFA600', width: '100%', height: '100%' }} />,
    title: 'Ubicación',
    links: `https://goo.gl/maps/${mapsLocation}`
  }
}

const AddYouTubes = (channelLocation: string): ISocial => {
  return {
    image: <YouTube sx={{ color: '#FF0000', width: '100%', height: '100%' }} />,
    title: 'YouTube',
    links: `https://www.youtube.com/channel/${channelLocation}`
  }
}

const AddLinkedIn = (profileURL: string): ISocial => {
  return {
    image: <LinkedIn sx={{ color: '#0077b5', width: '100%', height: '100%' }} />,
    title: 'LinkedIn',
    links: `https://www.linkedin.com/in/${profileURL}`
  }
}

const CardOptions: CardInterface[] = [
  {
    appPath: 'faraday',
    appLogo: 'faraday.jpg',
    iconArray: [
      AddLanguage('faradaymkt.com'),
      AddWhatsApp(528186901451),
      AddSendMail('contacto@faradaymkt.com'),
      AddFacebook('faradaymkt'),
      AddTwitters('faradaymkt'),
      AddInstaNew('faradaymkt'),
      AddLocation('D52QtZr6s7Zs9krU6'),
      AddYouTubes('UCNYW2vfGrUE6R5mIJYzkRyQ'),
      AddLinkedIn('luisenmarroquin')
    ]
  }, {
    appPath: 'gowiththeflow',
    appLogo: 'gowiththeflow.jpg',
    iconArray: [
      AddWhatsApp(528136019907),
      AddSendMail('psicyjennymoreno@gmail.com'),
      AddTwitters('gowiththeflow_byjm')
    ]
  }
]

export default CardOptions
