import './index.css'
import CardPage from './CardPage'
import { render } from 'react-dom'
import CardOptions from './CardOptions'
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom'

render(
  <BrowserRouter>
    <Routes>
      {
        CardOptions.map((option, index) => {
          return (
            <Route key={index} path={`${option.appPath}`} element={<CardPage customer={option} />} />
          )
        })
      }
      <Route path='*' element={<Navigate to="faraday" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)
